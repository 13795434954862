.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 25%;
  
  > * {
    max-width: 30ch;
    margin-bottom: 1rem;
  }

  > :last-child {
    margin-top: 2rem;
  }
}
