.select {
  background: $black;
  width: 648px;
  min-height: 64px;
  border: 3px solid $primary-color;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}
