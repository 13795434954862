.apipage {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;

  > form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 900px;

    > input {
      flex: 1;
      width: 100%;
      background-color: clr(gray, light);
      color: black;
      padding: 5px 10px;
      line-height: 40px;
      border: 0;
      font-size: 32px;
      font-family: 'CanaroBold';
      margin: 1rem 0;
    }
  }
}
