.setupPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-image: url('../../images/background-main.jpg');
  background-size: cover;
  background-repeat: no-repeat;

  > button {
    @include text(scut-rem(32px), 38px, bold);
  }

  > button:not(:last-child) {
    margin-bottom: scut-rem(33px);
  }
}
