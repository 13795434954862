.workout {
  padding: $margin-lg;
  height: 100vh;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;


  &__noBags {
    text-align: center;
    margin-top: 25%;
  }
}

.hitBag {
  position: absolute;
  bottom: 3%;
  right: 3%;
  z-index: 9999;
}
