@import './../../styles/utils/variables';

.DesignSystemPage {
  display: flex;

  > * {
    margin-right: $margin-lg;
  }

  &__space {
    height: $margin-sm;
  }
}
