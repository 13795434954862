.challengePopup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  > *:not(:last-child) {
    margin-bottom: scut-rem(32);
  }

  &__buttons {
    width: 100%;
    max-width: 648px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;

    > button {
      max-height: 64px !important;
      max-width: 308px !important;
      height: 100% !important;
      width: 100% !important;
    }
  }
}
