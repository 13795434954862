.homePage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-image: url('../../images/background-main.jpg');
  background-size: cover;
  background-repeat: no-repeat;

  &__startBtn {
    @include text(scut-rem(32px), 38px, bold);
    background-color: $primary-color;
    color: $white;
  }
}
