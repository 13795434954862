.icon {
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  display: inline-flex;

  > img {
    width: 100%;
    height: 100%;
    align-self: center;
  }

  & + & {
    margin: 0 0.2rem;
  }
}
