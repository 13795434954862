@import './../../styles/utils/variables';

.workoutCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 133px;
  background: $green-dark;
  border-radius: $margin-sm;
  padding: 0 $margin-md $margin-md;
  border: 3px solid $green-dark;

  &--success {
    border: 3px solid $primary-color;
  }

  &--failed {
    border: 3px solid $secondary-color;
  }

  &__id {
    min-height: $margin-lg;
    display: flex;
    align-items: center;
  }

  &__stats {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    &--singleStat {
      justify-content: flex-end;
      margin-bottom: $margin-sm;
      > span > p {
        font-size: scut-rem(48px) !important;
      }
    }

    > *:not(:last-child) {
      margin-bottom: 0.8rem;
    }

    > span {
      display: inline-flex;
      justify-content: space-between;
      width: -webkit-fill-available;

      > p {
        @include text(scut-rem(32px), 32px, bold);
        color: $white;
      }

      > *:first-child {
        color: $gray-light;
      }
    }
  }
}
