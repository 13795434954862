@font-face {
  font-family: 'CanaroBold';
  src: url('./fonts/canaro-bold.eot'); /* IE9*/
  src: url('./fonts/canaro-bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/canaro-bold.woff2') format('woff2'),
    /* chrome、firefox */ url('./fonts/canaro-bold.woff') format('woff'),
    /* chrome、firefox */ url('./fonts/canaro-bold.ttf') format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url('./fonts/canaro-bold.svg#Canaro W00 Bold') format('svg'); /* iOS 4.1- */
}

@font-face {
  font-family: 'CanaroBook';
  src: url('./fonts/CanaroBook.eot'); /* IE9*/
  src: url('./fonts/CanaroBook.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/CanaroBook.woff2') format('woff2'),
    /* chrome、firefox */ url('./fonts/CanaroBook.woff') format('woff'),
    /* chrome、firefox */ url('./fonts/CanaroBook.ttf') format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url('./fonts/CanaroBook.svg#Canaro W00 Book') format('svg'); /* iOS 4.1- */
}

html,
body {
  margin: 0 auto;
  color: white;
  font-family: 'CanaroBold';
}

h1,
p {
  margin: 0;
}
