@import './../../styles/utils/mixins';
@import './../../styles/utils/variables';

.paragraph {
  @include text(scut-rem(24), 29px);
}

.paragraph-s {
  @include text(scut-rem(12), 14.4px);
}

.head-1 {
  @include text(scut-rem(40), 48px);
}

.head-2 {
  @include text(scut-rem(34), 38.8px);
}

.head-3 {
  @include text(scut-rem(32), 38.4px);
}

.light {
  font-family: 'CanaroBook';
}

.gray {
  color: $gray-light;
}
