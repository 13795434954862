@import './utils/variables';

.nextround {
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  background-color: #182222;
  position: relative;
  overflow: hidden;
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;

  &:before {
    content: '';
    position: absolute;
    background-image: url('../images/logo-small.png');
    background-repeat: no-repeat;
    width: 78px;
    height: 48px;
    top: $margin-lg;
    left: $margin-lg;
  }
}
