.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 952px;
    max-height: 544px;
    border-radius: 0.5rem;
    background-color: $black;
    padding: $margin-md $margin-lg;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    width: 100%;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
  }

  &-open {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-closed {
    display: none;
  }
}
