@use 'sass:math';
/*
  TEXT MIXIN
  If the $size / $lineHeight / $weight arguments are not empty,
  then output the argument.
*/

@mixin text(
  $size: null,
  $lineHeight: null,
  $weight: null,
  $letter_spacing: null
) {
  @if $size != null {
    font-size: $size;
  }

  @if $lineHeight != null {
    line-height: $lineHeight;
  }

  @if $weight != null {
    font-weight: $weight;
  }
  @if $letter_spacing != null {
    letter-spacing: $letter_spacing;
  }
}

// ------

@function scut-strip-unit($num) {
  @return ($num / ($num * 0 + 1));
}

// This function works on converting px to em
$scut-em-base: 16 !default;

@function scut-em($pixels, $base: $scut-em-base) {
  $multiplier: if(unit($base) == em, 16, 1);
  $divisor: scut-strip-unit($base) * $multiplier;

  $em-vals: ();
  @each $val in $pixels {
    $val-in-ems: (scut-strip-unit($val) / $divisor) * 1em;
    $em-vals: append($em-vals, $val-in-ems);
  }

  @if length($em-vals) == 1 {
    @return nth($em-vals, 1);
  } @else {
    @return $em-vals;
  }
}

// This function works on converting px to rem
$scut-rem-base: 16 !default;

@function scut-rem($pixels) {
  $rem-vals: ();
  @each $val in $pixels {
    $val-in-rems: (scut-strip-unit($val) / $scut-rem-base) * 1rem;
    $rem-vals: append($rem-vals, $val-in-rems);
  }

  @if length($rem-vals) == 1 {
    @return nth($rem-vals, 1);
  } @else {
    @return $rem-vals;
  }
}
