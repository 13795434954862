.resetPopup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  > *:first-child {
    margin-bottom: scut-rem(50);
  }

  > *:nth-child(2) {
    margin-bottom: scut-rem(120);
    text-align: center;
    max-width: 42ch;
  }

  &__buttons {
    width: 100%;
    max-width: 648px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;

    > button {
      max-height: 64px !important;
      max-width: 308px !important;
      height: 100% !important;
      width: 100% !important;
    }
  }
}