$primary-color: #00c0ba;
$primary-light: #99e6e3;
$primary-dark: #006663;

$secondary-color: #fb7558;
$secondary-light: #fcac9a;

$black: #182222;
$black-light: #464e4e;

$gray-dark: #282e2e;
$gray-light: #b5b5b5;

$green-dark: #283939;

$white: #FFFFFF;

$player1: $primary-color;
$player1Inactive: rgba(0, 192, 186, 0.4);
$player2: #ffbd2c;
$player2Inactive: rgba(255, 189, 44, 0.4);

$shadow: 0px 0px 7px 5px rgba(0, 0, 0, 0.3);

// margins
$margin-sm: 1rem;
$margin-md: 1.25rem;
$margin-lg: 2rem;
