.inputField {
  background: $black;
  width: 648px;
  height: 64px;
  border: 3px solid $primary-color;
  box-sizing: border-box;
  border-radius: 16px;
}

.inputField::placeholder {
  @include text(scut-rem(24), 29px);
  color: $gray-light;
  padding-left: 30px;
}
