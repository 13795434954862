@import './../../styles/utils/variables';

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  color: $white;
  font-family: 'CanaroBold';

  &--standard {
    width: 13.125rem;
    max-width: 13.125rem;
    height: 3rem;
    max-height: 3rem;
  }

  &--large {
    width: 27.875rem;
    max-width: 27.875rem;
    height: 4rem;
    max-height: 4rem;
  }

  &--square {
    width: 3rem;
    max-width: 3rem;
    height: 3rem;
    max-height: 3rem;
  }

  &--primary {
    background-color: transparent;
    border: 3px solid $primary-color;
  }

  &--secondary {
    background-color: $primary-color;
    border: 3px solid $primary-color;
  }

  &--tertiary {
    background-color: transparent;
    border: 3px solid $secondary-color;
  }

  &--disabled {
    background-color: $gray-light;
    border: 3px solid $gray-light;
  }
}
