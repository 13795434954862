@import './../../styles/utils/variables';

$padding: $margin-lg * 2;

// #{$margin-md} represents the margin-top of .gridWrapper
$grid-wrapper-height: calc(85vh - #{$margin-md} - #{$padding});

.gridWrapper {
  height: $grid-wrapper-height;
  min-height: $grid-wrapper-height;
  margin-top: $margin-md;

  &--scrollable {
    overflow-y: scroll;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;

    &--top:before {
      content: '';
      position: absolute;
      top: 75%;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.5) 75%,
        rgba(0, 0, 0, 1) 100%
      );
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.cardsGrid {
  display: grid;
  display: -ms-grid;
  -ms-grid-columns: repeat(4, minmax(200px, 1fr));
  grid-template-columns: repeat(4, minmax(200px, 1fr));
  grid-gap: $margin-md;
  width: 100%;
}

#avgCard {
  background-color: $primary-color;

  > div > span {
    > span {
      display: inline-flex;
      align-items: baseline;
      justify-content: center;
      > p {
        color: $white;
        margin-right: 5px;
      }
    }
  }
}
