@import './../../styles/utils/variables';
@import './../../styles/utils/mixins';

.toolbar {
  display: grid;
  min-height: 15vh;
  height: 15vh;
  display: -ms-grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(6, 1fr);
  justify-items: center;
  align-items: center;
  gap: $margin-md;
  grid-template-areas:
    '. players time time config endTraining'
    '. hits force speed intensity .';

  &__players {
    position: relative;
    grid-area: players;
    align-self: end;
    justify-self: start;

    &__title {
      position: absolute;
      width: 100%;
      top: -$margin-lg;
      font-size: 1rem;
      text-align: center;
    }
  }

  &__duration {
    grid-area: duration;
    justify-self: end;
    align-self: end;
  }

  &__time {
    grid-area: time;
    justify-self: center;
    position: relative;
    align-self: end;

    > h1 {
      @include text(scut-rem(48px), 48px, bold);
    }

    > p {
      @include text(scut-rem(20px), 24px, bold);
      position: absolute;
      top: -$margin-sm * 1.25;
      width: 100%;
      text-align: center;
    }
  }

  &__endTraining {
    grid-area: endTraining;
    justify-self: end;
    align-self: end;
    height: 3rem;
    width: 50% !important;
  }

  &__realTime {
    grid-area: realTime;
    justify-self: end;
    align-self: end;
  }

  &__hits {
    grid-area: hits;
  }

  &__force {
    grid-area: force;
  }

  &__speed {
    grid-area: speed;
  }

  &__intensity {
    grid-area: intensity;
  }

  &__config {
    grid-area: config;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-self: end;
    gap: $margin-sm;
  }
}
