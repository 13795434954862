.pausePopup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  > *:first-child {
    margin-bottom: scut-rem(50);
  }

  > *:nth-child(2) {
    margin-bottom: scut-rem(120);
    text-align: center;
    max-width: 42ch;
  }

  > button {
    max-height: 64px !important;
    max-width: 308px !important;
    height: 100% !important;
    width: 100% !important;
  }
}